import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import logo from '../../../assets/logo.gif';
import { MobileMenu } from './MobileMenu';
import { MainMenu } from './MainMenu';

export type RouteType = {
	hasChildren: boolean;
	children?: {
		path: string;
		name?: string;
	}[];
	defaultLayout: boolean;
	main: ReactElement;
	name: string;
	path: string;
};

export const NavMenu = ({
	routes,
	width,
}: {
	routes: RouteType[];
	width: number;
}): ReactElement =>
{
	const { pathname } = useLocation();
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) =>
	{
		if (anchorEl !== event.currentTarget) setAnchorEl(event.currentTarget);
	};
	const handleClose = () => setAnchorEl(null);

	return (
		<AppBar
			sx={{
				alignItems: 'center',
				backgroundColor: 'black',
				height: '65px',
			}}
		>
			{width < 1420 && (
				<MobileMenu anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} logo={logo} open={open} pathname={pathname} width={width} />
			)}
			{width > 1420 && (
				<MainMenu logo={logo} pathname={pathname} width={width} />
			)}
		</AppBar>
	);
};

import { Box, Tabs, Tab, Toolbar } from "@mui/material";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { LogoButton } from "./LogoButton";
import { RouteType } from "./NavMenu";

type Props = {
    logo: string;
    pathname: string;
    width: number;
};

export const MainMenu = ({ logo, pathname, width }: Props): ReactElement =>
{
    return <Toolbar
        className="MenuBar-width"
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        <LogoButton logo={logo} width={width} />
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Tabs textColor="inherit"
                indicatorColor="secondary"
                sx={{
                    '& div > a': {
                        color: '#d6d6d6',
                        opacity: 1,
                        '&:hover': {
                            color: 'white',
                            opacity: 1,
                            transition: 'opacity .2s ease-in-out'
                        }
                    },
                    '& div > .Mui-selected': {
                        color: 'white'
                    }
                }}
                value={pathname}
            >
                {routes.map((route: RouteType, index: number) =>
                (
                    <Tab key={route.name + index} label={route.name} value={route.path} to={route.path} component={Link} state={{ previousPath: pathname }} />
                ))}
            </Tabs>
        </Box>
    </Toolbar>;
};

import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const Publications = () =>
{
    const publications = [
        {
            title: 'Pacyniak, E. K., Cheng, X., Cunningham, M.L., Crofton, K., Klaassen, C. D., and Guo, G. L.',
            journal: 'The flame retardants polybrominated diphenyl ethers are pregnane X receptor activators',
            source: 'Tox. Sci. 97: 94-102., 2007'
        },
        {
            title: 'Ekins, S., Chang, C., Mani, S., Krasowski, M. D., Reschly, E.J., Iyer, M., Kyolodovych, V., Ai, N., Welsh, W. J., Sinz, M., Swaan, P. W., Patel, R.',
            journal: 'Mol. Pharm. 72: 592-603, 2007'
        },
        {
            title: 'Yasuda, K. Ranade, A., Venkataramanan, R., Strom, S., Chupka, J., Ekins, S., Schuetz, E., and Bachmann, K.',
            journal: 'A Comprehensive in Vitro and in Silico Analysis of Antibiotics that Activate Pregnane X Receptor and Induce CYP3A4 in Liver and Intestine',
            source: 'Drug Met. Disp. 36 (8): 1689-1697, 2008'
        },
        {
            title: 'E. Lipp.',
            journal: 'Tackling Drug-Interaction Issues Early On',
            source: 'Gen. Eng. Biotech. News 28 (12): 14-20, 2008'
        },
        {
            title: 'Wang, L, Li F, Lu, J, Li G, Li D, Zhong, XB, Guo, GL, Ma, X.',
            journal: 'The Chinese herbal medicine sophora flavescens activates pregnane X receptor',
            source: 'Drug Metab. Dispos. 38: 2226-2231, 2010'
        },
        {
            title: 'Kuzbari O, Peterson CM, Franklin MR, Hathaway LB, Johnstone EB, Hammoud AO, Lamb JG.',
            journal: 'Comparative analysis of human CYP3A4 and rat CYP3A1 induction and relevant gene expression by bisphenol A and diethylstilbesterol: Implications for toxicity testing paradigms',
            source: 'Reprod Toxicol. 37: 24-30, 2013'
        },
        {
            title: 'Roberts JK, Moore CD, Ward RM, Yost GS, Reilly CA.',
            journal: 'Metabolism of beclomethasone dipropionate by cytochrome P450 3A enzymes',
            source: 'J Pharmacol. Exp Ther. 345: 308-316, 2013'
        },
        {
            title: 'Allen, S. W., Mueller, L., Williams, S., Quattrochi, L., and Raucy, J.',
            journal: 'The use of a high volume screening procedure to assess the effects of dietary flavonoids on human CYP1A1 expression',
            source: 'Drug Metab. Disp. 29: 1074-1079, 2001'
        },
        {
            title: 'Raucy, Judy; Lasker, Jerome; Ozaki, Kazuaki; and Zoleta, Veronica',
            journal: 'Regulation of CYP2E1 by ethanol and palmitic acid and CYP4A11 by clofibrate in primary cultures of human hepatocytes',
            source: 'Toxicol. Sci. 79: 233-241, 2004'
        },
        {
            title: 'Burk, O., Koch, I., Raucy, J., Hustert, E., Eichelbaum, M., Brockmoller, J., Zanger, U. M., Wojnowski, L.',
            journal: 'The Induction of Cytochrome P450 3A5 (CYP3A5) in the Human Liver and Intestine Is Mediated by the Xenobiotic Sensors Pregnane X Receptor (PXR) and Constitutively Activated Receptor (CAR)',
            source: 'J. Biol. Chem. 279: 38379-38385, 2004'
        },
        {
            title: 'Yueh, M-F., Kawahara, M. and Raucy, J.',
            journal: 'Cell-based High-Throughput Bioassays to Assess Induction and Inhibition of CYP1A Enzymes',
            source: 'Toxicology In Vitro. 19: 275-287, 2005'
        },
        {
            title: 'Yueh, M-F., Kawahara, M., and Raucy, J. L.',
            journal: 'High-throughput bioassays to assess CYP3A4-mediated drug interactions: Induction and inhibition',
            source: 'Drug Metab. Disp. 33: 38-48, 2005'
        },
        {
            title: 'Trubetskoy O, Marks B, Zielinski T, Yueh M-F, Raucy J.',
            journal: 'A Simultaneous Assessment of CYP3A4 Metabolism and Induction in the DPX-2 Cell Line',
            source: 'AAPS Journal 07(01): E6-E13, 2005'
        },
        {
            title: 'Michael W. Sinz, Devin Pray, Judy Raucy',
            journal: 'The utility of stable cell lines to assess species differences in PXR transactivation',
            source: 'Drug Metab. Letts. 1, (2): 147-152, 2007'
        },
        {
            title: 'Lamb, J. G., Hathaway, LB., Munger, M.A., Raucy, J. L., Franklin, M. R.',
            journal: 'Nanosilver particle effects on drug metabolism in vitro',
            source: 'Drug Metab. Disp. 38: 2246-2251, 2010'
        },
        {
            title: 'Raucy, J. L., Lasker, J. M.',
            journal: 'Current in vitro high throughput screening approaches to assess nuclear receptor activation',
            source: 'Curr. Drug Metab. 11: 806-814, 2010'
        },
        {
            title: 'Fahmi, O. A., Raucy, J. L., Ponce, E. Hassanali, S. Lasker J.M.',
            journal: 'Utility of DPX2 cells for predicting CYP3A induction-mediated drug-drug Interactions and associated structure-activity relationships',
            source: 'Drug Metab. Disp. 40: 2204-2211, 2012'
        },
        {
            title: 'Raucy, J. L., Lasker, J. M.',
            journal: 'Cell-based systems to assess nuclear receptor activation and their use in drug development',
            source: 'Drug Metab. Rev. 45: 101-109, 2013'
        },
    ];
    return (
        <List>
            {publications.map((publication, index) => (
                <ListItem key={index}>
                    <ListItemText
                        primary={publication.title}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" color="textPrimary">
                                    {publication.journal}
                                </Typography>
                                {publication.source && `, ${publication.source}`}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default Publications;

import { Container, Typography, Box, Skeleton } from "@mui/material";
import { ReactElement } from "react";
import { Image } from 'mui-image';

type Props = {
    image: string;
};

export const MainHeader = (props: Props): ReactElement =>
{
    return <Container maxWidth="xl" sx={{
        position: 'relative',
        overflow: 'hidden',
        marginBottom: '48px'
    }}>
        <Box sx={{
            backgroundColor: 'rgba(0,0,0,0.35)',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute', left: 24, right: 24, top: 0, bottom: 6
        }}>
            <Typography variant="h4" sx={{
                color: 'white',
                fontWeight: 'bold',
                left: '5%',
                textAlign: 'center'
            }}>
                The World Leader In Transcriptional Analysis of ADMET Genes
            </Typography>
        </Box>
        {props.image ?
            <Box alt="Laboratory Work" component="img" sx={{ borderRadius: '4px', height: '550px', width: '100%' }} src={props.image} />
            :
            <Skeleton animation="wave" width="100%" height="100%" />
        }
    </Container >;
};
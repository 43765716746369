import { IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { RouteType } from "./NavMenu";
import MenuIcon from '@mui/icons-material/Menu';
import { LogoButton } from "./LogoButton";

type Props = {
    anchorEl: any;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleClose: (event: React.MouseEvent<HTMLElement>) => void;
    logo: string;
    open: boolean;
    pathname: string;
    width: number;
};

export const MobileMenu = ({ anchorEl, handleClick, handleClose, logo, open, pathname, width }: Props): ReactElement =>
{
    return <Toolbar
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'calc(100vw - 24px)'
        }}
    >
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
        >
            <MenuIcon />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {routes.map(({ path, name }: RouteType) => (
                    <Link key={path} to={path} state={{ previousPath: pathname }}>
                        <MenuItem
                            sx={[
                                {
                                    color: 'black',
                                    button: {
                                        '&:hover': {
                                            borderColor: '1px solid white',
                                        },
                                    },
                                    textDecoration: 'none',
                                },
                            ]}
                        >
                            <Typography textAlign="center">{name}</Typography>
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </IconButton>
        <Typography style={{
            color: 'white',
            fontSize: 32,
            fontWeight: 800,
            lineHeight: 1,
            marginBottom: 0,
        }}
        >
            PURACYP
        </Typography>
        <LogoButton logo={logo} width={width} />
    </Toolbar>;
};

import { About } from 'components/Layout/Pages/About';
import { Home } from 'components/Layout/Pages/Home';
import { Services } from 'components/Layout/Pages/Services';
import { Contact } from 'components/Layout/Pages/Contact';
import { Products } from 'components/Layout/Pages/Products';
import Purchase from 'components/Layout/Pages/Purchase';


export const routes = [
	{
		defaultLayout: true,
		main: <Home />,
		name: 'Home',
		path: '/',
		hasChildren: false,
	},
	{
		defaultLayout: true,
		main: <Products />,
		name: 'Products',
		path: '/products',
		hasChildren: false,
	},
	{
		defaultLayout: true,
		main: <Services />,
		name: 'Services',
		path: '/services/*',
		hasChildren: false,
	},
	{
		defaultLayout: true,
		main: <About />,
		name: 'About',
		path: '/about',
		hasChildren: false,
	},
	{
		defaultLayout: true,
		main: <Contact />,
		name: 'Contact',
		path: '/contact',
		hasChildren: false,
	}
];

import { ReactElement } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const headers = [
	'AhR',
	'PXR',
	'CAR1',
	'CAR3',
	'PPARα',
	'PPARγ',
	'NRF2',
	'ERa',
	'GR',
	'FXR',
	'LXRa',
	'VDR',
];

export const createTableData = (
	name: string,
	id: number,
	AhR: ReactElement,
	PXR: ReactElement,
	CAR1: ReactElement,
	CAR3: ReactElement,
	PPARα: ReactElement,
	PPARγ: ReactElement,
	NRF2: ReactElement,
	ERa: ReactElement,
	GR: ReactElement,
	FXR: ReactElement,
	LXRa: ReactElement,
	VDR: ReactElement
) => {
	return {
		name,
		id,
		AhR,
		PXR,
		CAR1,
		CAR3,
		PPARα,
		PPARγ,
		NRF2,
		ERa,
		GR,
		FXR,
		LXRa,
		VDR,
	};
};

export const rows = [
	createTableData(
		'Human',
		0,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />
	),
	createTableData(
		'Rat',
		1,
		<CheckIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />
	),
	createTableData(
		'Monkey (Rhesus)',
		2,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />
	),
	createTableData(
		'Canine (Beagle)',
		3,
		<CheckIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />
	),
	createTableData(
		'Murine',
		4,
		<CheckIcon />,
		<CheckIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />
	),
	createTableData(
		'Sheep (Ovaris Aries)',
		5,
		<RemoveCircleOutlineIcon />,
		<CheckIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />,
		<RemoveCircleOutlineIcon />
	),
];

import React, { ReactNode } from 'react';
import { Card, CardContent, CardHeader, CardMedia, Container, Grid, Typography } from '@mui/material';

type Props = {
	cardOverlay?: boolean;
	content?: string | ReactNode;
	media?: string;
	mediaArray?: string[];
	mediaHeight?: number;
	mediaStyle?: any;
	mediaTitle?: string;
	mediaWidth?: string | number;
	sx?: any;
	title?: string | ReactNode;
};

export const InfoCard = ({ cardOverlay = true, content, media = undefined, mediaArray, mediaHeight, mediaStyle, mediaTitle, mediaWidth, sx, title }: Props) =>
{
	return (
		<Container maxWidth="xl" sx={sx}>
			<Card sx={{
				marginTop: '24px'
			}} >
				<CardHeader
					sx={{
						paddingBottom: !media ? 0 : undefined
					}}
					title={<Typography variant='h5' color="text.primary" sx={{
						paddingLeft: 1
					}}>
						<strong>
							{title}
						</strong>
					</Typography>}
				/>
				{
					media && (media ? cardOverlay &&
						<div>
							<CardMedia
								component="img"
								height={mediaHeight}
								image={media}
								alt={mediaTitle}
								sx={{
									...mediaStyle,
									width: mediaWidth
								}}
							/>
						</div>
						:
						<CardMedia
							component="img"
							height={mediaHeight}
							image={media}
							alt={mediaTitle}
						/>)
				}
				{
					mediaArray && (
						<Grid container spacing={2}>
							{
								mediaArray.length > 2 ?
									(mediaArray?.map((image) =>
										<Grid item xs={4}>
											<CardMedia
												component="img"
												height={mediaHeight}
												image={image}
												alt={mediaTitle}
											/>
										</Grid>
									)) : (mediaArray?.map((image) =>
										<Grid item xs={6}>
											<CardMedia
												component="img"
												height={mediaHeight}
												image={image}
												alt={mediaTitle}
											/>
										</Grid>
									))
							}
						</Grid>
					)
				}
				<CardContent
					sx={{
						paddingTop: !media ? 0 : undefined,
						paddingLeft: 0,
						paddingRight: 0
					}}>
					<Container maxWidth="xl" >
						{content}
					</Container>
				</CardContent>
			</Card>
		</Container>
	);
};

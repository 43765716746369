import { Card, CardContent, Container, Typography } from "@mui/material";
import { ReactElement } from "react";

export const MainContent = (): ReactElement =>
{
    return (
        <Container maxWidth="xl" >
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography variant="body1" color="text.primary" sx={{ fontSize: 18, marginTop: 2 }}>
                        Assessing gene transcription as an endpoint for determining
                        induction of drug metabolizing enzymes and transporters has become
                        the “method of choice” in the FDA’s latest draft Guidance for
                        Industry drug interaction studies.
                    </Typography>
                    <Typography variant="body1" color="text.primary" sx={{ fontSize: 18, marginTop: 2 }}>
                        Increased gene transcription due
                        to NCE exposure can be determined by two distinct methods; nuclear
                        receptor activation, and changes in mRNA levels in primary cultures.
                    </Typography>
                    <Typography variant="body1" color="text.primary" sx={{ fontSize: 18, marginTop: 2 }}>
                        <strong>Puracyp</strong> has become THE “gene transcription company” by providing
                        both of these services. Our newest products meet the regulatory
                        challenges associated with induction guidelines.
                    </Typography>
                </CardContent>
            </Card>
        </Container >
    );
};

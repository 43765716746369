import
{
    Box,
    Link as LinkButton,
    Typography,
} from '@mui/material';
import { ReactElement } from 'react';

type Props = {
    logo: string;
    width: number;
};

export const LogoButton = ({
    logo,
    width
}: Props): ReactElement =>
{
    return <LinkButton
        href="/"
        sx={{
            alignItems: 'center',
            display: 'flex',
            height: 65,
            textDecoration: 'none',
            '& > div > p': {
                color: 'black',
                marginLeft: width > 1420 ? 1 : 0,
                marginRight: width < 1420 ? 1 : 0,
                '&:first-p:hover': {
                    color: 'gray',
                    transition: 'all .2s ease-in-out'
                },
            },
        }}
    >
        <Box alt="Logo" component="img" sx={{ width: 80 }} src={logo} />
        {width > 1420 && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginLeft: '10px'
            }}>
                <Typography style={{
                    color: 'white',
                    fontSize: 32,
                    fontWeight: 800,
                    lineHeight: 1,
                    marginBottom: 0,
                }}
                >
                    PURACYP
                </Typography>
                <Typography style={{ color: 'lightgray', fontSize: 16, lineHeight: 1, }}>
                    The Original Nuclear Receptor Company
                </Typography>
            </Box>
        )}
    </LinkButton>;
};

import { Card, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const DosingMediaChart = (props: Props) =>
{
    const data = [
        { catalogNumber: 'DPX2-C50-100', volume: 50, link: 'http://puracyp.com/lit/DPX296.pdf' },
        { catalogNumber: 'DPX2 C125-100', volume: 125, link: 'http://puracyp.com/lit/DPX2384.pdf' },
        { catalogNumber: '1A2-DRE C50-101', volume: 50, link: 'http://puracyp.com/lit/1A2DRE96.pdf' },
        { catalogNumber: '1A2-DRE C125-101', volume: 125, link: 'http://puracyp.com/lit/1A2DRE384.pdf' },
        { catalogNumber: 'RPXR C50-102', volume: 50, link: 'http://puracyp.com/lit/rPXR96.pdf' },
        { catalogNumber: 'RPXR C125-102', volume: 125, link: 'http://puracyp.com/lit/rPXR384.pdf' },
        { catalogNumber: 'DPX2 D50-100', volume: 50, link: 'http://puracyp.com/lit/DPX296.pdf' },
        { catalogNumber: 'DPX2 D125-100', volume: 125, link: 'http://puracyp.com/lit/DPX2384.pdf' },
        { catalogNumber: '1A2-DRE D50-101', volume: 50, link: 'http://puracyp.com/lit/1A2DRE96.pdf' },
        { catalogNumber: '1A2-DRE D125-101', volume: 125, link: 'http://puracyp.com/lit/1A2DRE384.pdf' },
        { catalogNumber: 'RPXR D50-102', volume: 50, link: 'http://puracyp.com/lit/rPXR96.pdf' },
        { catalogNumber: 'RPXR D125-102', volume: 125, link: 'http://puracyp.com/lit/rPXR384.pdf' },
    ];
    return (
        <TableContainer component={Paper} elevation={0}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>
                                Catalog Number
                            </strong>
                        </TableCell>
                        <TableCell>
                            <strong>
                                Volume (ml)
                            </strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Link style={{
                                    color: 'purple',
                                    fontWeight: 'bold',
                                }} to={row.link} target="_blank" rel="noopener noreferrer">
                                    {row.catalogNumber}
                                </Link>
                            </TableCell>
                            <TableCell>{row.volume}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DosingMediaChart;
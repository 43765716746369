import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {};

const DosingMediaChart = (props: Props) =>
{
    const data = [
        { catalogNumber: 'DPX2-ACTIV', description: 'Human PXR activation and cell viability', link: 'https://puracyp.com/lit/DPX2ActiveManual.pdf' },
        { catalogNumber: 'DRE-1A2-ACTIV', description: 'Human AhR activation and cell viability', link: 'https://puracyp.com/lit/1A2DREManual.pdf' },
        { catalogNumber: 'RPXR-ACTIV', description: 'Rat PXR activation and cell viability', link: 'https://puracyp.com/lit/rPXRManual.pdf' },
        { catalogNumber: 'hCAR1-ACTIV', description: 'Human CAR1 activation and cell viability', link: 'https://puracyp.com/lit/hCAR196Manual.pdf' },
        { catalogNumber: 'hCAR3-ACTIV', description: 'Human CAR1 activation and cell viability in 96 well plate', link: 'https://puracyp.com/lit/hCAR3Manual.pdf' },
    ];
    return (
        <TableContainer component={Paper} elevation={0}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>
                                Catalog Number
                            </strong>
                        </TableCell>
                        <TableCell>
                            <strong>
                                Description
                            </strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Link style={{
                                    color: 'purple',
                                    fontWeight: 'bold',
                                }} to={row.link} target="_blank" rel="noopener noreferrer">
                                    {row.catalogNumber}
                                </Link>
                            </TableCell>
                            <TableCell>{row.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DosingMediaChart;
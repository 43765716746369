import { ReactElement } from 'react';
import headerImg from '../../../assets/bio4.jpeg';
import Image4 from '../../../assets/bio13.jpeg';
import { Advantages } from 'components/Sections/Advantages';
import { MainHeader } from 'components/Sections/MainHeader';
import { MainContent } from 'components/Sections/MainContent';

export const Home = (): ReactElement =>
{
	return (
		<>
			<MainHeader image={headerImg} />
			<MainContent />
			<Advantages image={Image4} />
			{/* <ContactSection /> */}
		</>
	);
};

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { useStyles } from './AssayChart.styles';
import { headers, rows } from './AssayData';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: 'gray',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

export const AssayChart = (): ReactElement => {
	const styles = useStyles();
	return (
		<div className={`${styles.AssayChart}`}>
			<div className={`${styles.Chart}`}>
				<TableContainer component={Paper}>
					<Table size="small" sx={{ minWidth: 650 }}>
						<TableHead>
							<TableRow>
								<StyledTableCell component="th">Species</StyledTableCell>
								{headers.map((header, index) => (
									<StyledTableCell align="right" key={index}>{header}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => 
								<TableRow
									key={row.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" key={0} scope="row">
										{row.name}
									</TableCell>
									<TableCell align="right" key={1} >{row.AhR}</TableCell>
									<TableCell align="right" key={2} >{row.PXR}</TableCell>
									<TableCell align="right" key={3} >{row.CAR1}</TableCell>
									<TableCell align="right" key={4} >{row.CAR3}</TableCell>
									<TableCell align="right" key={5} >{row.PPARα}</TableCell>
									<TableCell align="right" key={6} >{row.PPARγ}</TableCell>
									<TableCell align="right" key={7} >{row.NRF2}</TableCell>
									<TableCell align="right" key={8} >{row.ERa}</TableCell>
									<TableCell align="right" key={9} >{row.GR}</TableCell>
									<TableCell align="right" key={10} >{row.FXR}</TableCell>
									<TableCell align="right" key={11} >{row.LXRa}</TableCell>
									<TableCell align="right" key={12} >{row.VDR}</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};

export const useStyles = () => ({
	AssayChart: {
		marginBottom: '20px',
		width: '100%',
	},
	Chart: {
		overflowX: 'auto',
	},
	Table: {
		borderCollapse: 'collapse',
		width: '100%',
	},
});

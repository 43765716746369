import { ReactElement } from "react";
import { Container, Box, Typography, List, ListItem, ListItemText, Skeleton, Card, CardContent } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';


type Props = {
    image: string;
};

export const Advantages = (props: Props): ReactElement =>
{
    return <Container maxWidth="xl" sx={{ margin: '48px 0' }}>
        <Grid container spacing={2}>
            <Grid md={6} xs={12}>
                <Typography variant="h4" color="text.primary" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Our Advantages
                </Typography>
                {props.image ?
                    <Box alt="Laboratory Work" component="img" sx={{ borderRadius: '4px', width: '100%' }} src={props.image} />
                    :
                    <Skeleton animation="wave" width="100%" height="100%" />
                }
            </Grid>
            <Grid md={6} xs={12}>
                <List>
                    <ListItem disableGutters>
                        <ListItemText color="text.primary">
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    Employing reporter genes governed by the P450 promoters allows
                                    for accurate gene activation from the ligand-activated receptor.
                                    Correlations between binding affinity and activation are more
                                    readily predicted.
                                </CardContent>
                            </Card>
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText color="text.primary">
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    When ligands bind to the full-length receptor they can produce
                                    conformational alterations that influence the extent of gene
                                    transcription. <strong>
                                        Our competitors employ only the ligand binding
                                        domain (LBD) of the receptor.
                                    </strong>
                                </CardContent>
                            </Card>
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText color="text.primary">
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    Low variability and high reproducibility with results comparable
                                    to those obtained in primary cultures. Simple and easy to
                                    perform, with completion in 24 to 48 hours. Indirect NR
                                    activators can be identified. <strong>
                                        Indirect activators cannot be
                                        detected with mammalian two-hybrid or ligand binding assays.
                                    </strong>
                                </CardContent>
                            </Card>
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText color="text.primary">
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    Puracyp is the only nuclear receptor (NR) company that has
                                    functional assays allowing agonists to be differentiated from
                                    antagonist. Our competitors assays only determine binding to the
                                    LBD. <strong>
                                        These assays cannot identify agonism vs antagonism.
                                    </strong>
                                </CardContent>
                            </Card>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    </Container>;
};

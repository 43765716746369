import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement } from 'react';
import MainImage from '../../../assets/bio7.jpeg';
import Image2 from '../../../assets/bio2.jpeg';
import Image3 from '../../../assets/bio3.jpeg';
import Image4 from '../../../assets/hpxrrif.png';
import Image5 from '../../../assets/hcar1.gif';
import Image6 from '../../../assets/hcar3.gif';
import Image7 from '../../../assets/hcar3c.png';
import Image8 from '../../../assets/hahrbnf.png';
import Image9 from '../../../assets/pc.gif';
import Image10 from '../../../assets/rcar3.png';
import Image11 from '../../../assets/rahr.gif';
import Image12 from '../../../assets/mpxr.gif';
import Image13 from '../../../assets/mcar1.gif';
import Image14 from '../../../assets/ccar3.gif';
import Image15 from '../../../assets/vdr.png';
import Image16 from '../../../assets/4a.gif';
import Image17 from '../../../assets/4b.gif';
import { InfoCard } from '../Card';
import { AssayChart } from 'components/AssayChart/AssayChart';


export const Services = (): ReactElement =>
(
	<Box>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard content={
					<>
						<Divider />
						<Box sx={{ margin: '16px 0' }}>
							<strong>
								Nuclear Receptor Assays Currently Available
							</strong>
							<AssayChart />
						</Box>
						<Box sx={{ margin: '16px 0' }}>
							<Typography>
								Puracyp is dedicated to providing high quality research with a primary focus on high volume screening of new chemical entities to identify potential inducers of P450 enzymes and ABC transporters. Puracyp’s nuclear receptor activation service utilizes either stably integrated cell lines or transiently transfected cells harboring P450 enhancer elements in reporter gene constructs and human or animal nuclear receptors including PXR, CAR and AhR. Nuclear receptor assays currently available include those employing the CYP3A, CYP2B, P-glycoprotein or CYP1A2-DRE enhancers and promoters.
							</Typography>
						</Box>
						<Divider />
						<Box sx={{ margin: '16px 0' }}>
							<Typography>
								Nuclear receptors derived from various species are also available that can alleviate many animal studies and provide valuable information regarding a NCE and its potential to alter pharmacokinetic parameters. Information gained from employing human nuclear receptors can be used to predict in vivo responses from NCEs with regards to drug-drug interactions and adverse drug effects.
							</Typography>
						</Box>
						<Divider />
						<Box sx={{ margin: '16px 0' }}>
							<Typography>
								Puracyp’s scientists are extensively published and have been involved in human P450 metabolism research for a number of years. Presently, the company has two main divisions, products and services. Products now include kits that are all inclusive and contain our stably integrated human or rat PXR and human AhR cell lines. Puracyp also offers vials of our cryopreserved DPX2, 1A2-DRE and rPXR cells in 6 or 12 million cells per vial that can be formatted for 96 or 384 well plate analyses.
							</Typography>
						</Box>
					</>
				} media={MainImage}
					mediaHeight={394}
					mediaTitle="Cells"
					title="Services" />
			</Grid>
		</Grid>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							Screening for nuclear receptor activation is a widely used and
							accepted practice to predict adverse drug effects and
							drug-drug interactions. Moreover, assessing nuclear receptor
							activation meets the latest FDA guidance for industry on
							Drug-Drug Interactions.
						</Typography>
					)}
					media={Image2}
					mediaHeight={494}
					mediaTitle="Laboratory Work"
					title={(
						<strong>
							Nuclear Receptor Activation
						</strong>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							Our basic study design includes 6 concentrations in duplicate that can be used to calculate EC50 values (see figure below). Also included in the study results are positive and negative (solvent) controls and cell viability (reports toxicity of the test compound). A sample screening report is shown here. Once you receive your data, here is an explanation for interpreting the results.
						</Typography>
					)}
					media={Image3}
					mediaHeight={494}
					mediaTitle="Laboratory Work"
					title={(
						<strong>
							Basic Study Design
						</strong>
					)}
				/>
			</Grid>
		</Grid >
		<Grid container spacing={2}>
			<Grid item sm={6} xs={12}>
				<InfoCard
					content={(
						<></>
					)}
					media={Image4}
					mediaHeight={494}
				/>
			</Grid>
			<Grid item sm={6} xs={12}>
				<InfoCard
					content={(
						<>
							<Box sx={{ margin: '16px 0' }}>
								<Typography variant="body1" color="text.primary">
									<strong>
										A typical dose-response curve for rifampicin generated in human PXR, DPX2, cells. Results are expressed as fold induction above the negative control (DMSO) and were calculated by determining luminescence and normalizing to the viable cell number.
									</strong>
								</Typography>
							</Box>
						</>
					)}
				/>
				<InfoCard
					content={(
						<>
							<Box sx={{ margin: '16px 0' }}>
								<Typography variant="body1" color="text.primary">
									<strong>
										In keeping with Puracyp’s commitment to provide high quality results in a rapid throughput manner, study turnaround time is 7 business days from receipt of compounds.
									</strong>
								</Typography>
							</Box>
							<Divider />
							<Box sx={{ margin: '16px 0' }}>
								<Typography variant="h5" color="text.primary">
									<strong>
										PLEASE CONTACT US FOR PRICING DETAILS
									</strong>
								</Typography>
							</Box>
						</>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<>
							<Typography variant="body1" color="text.primary">
								Puracyp offers single assays or panel screens at a discounted price. Our panels are designed to address the FDA’s recent guidance that CYP2B6, CYP1A2 and CYP3A4 induction by NCEs be evaluated. Puracyp’s cells contain the promoter/enhancer of these three P450 enzymes in addition to either CAR, AhR, or PXR.
							</Typography>
							<Typography variant="body1" color="text.primary">
								Panel screens are also useful for examining species differences in P450 induction and for toxicology studies using orthologs of human nuclear receptors. An example of results obtained in human cell lines utilizing our panel screen for PXR, AhR and CAR are shown below.
							</Typography>
						</>
					)}
					mediaArray={[ Image4, Image5, Image6, Image7, Image8, Image9 ]}
					mediaTitle=""
					title={(
						<strong>
							Nuclear Receptor Screens
						</strong>
					)}
				/>
			</Grid>
		</Grid >
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							Mix and match any receptor or species ortholog to develop your own nuclear receptor activation panel. An example of the available assays to use in your study design are shown below.
						</Typography>
					)}
					mediaArray={[ Image10, Image11, Image12, Image13, Image14, Image15 ]}
					title={(
						<strong>
							Custom Designed Panel Assays
						</strong>
					)}
				/>
			</Grid>
		</Grid >
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							Puracyp’s cell lines can be used in a multiplex fashion that includes nuclear receptor activation data, cellular toxicity of test compounds, and to determine P450 metabolism of a luminescent substrate, such as P450 Glo™. Assessing P450 activity in Puracyp’s cell lines provides information to identify compounds that are both inducers and inhibitors. All of this information can be obtained in a single well.
						</Typography>
					)}
					mediaArray={[ Image16, Image17 ]}
					title={(
						<strong>
							Multiplex Analyses
						</strong>
					)}
				/>
			</Grid>
		</Grid >
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							Using DPX2 cells, it is possible to identify inducers of CYP3A4 by assessing PXR activation and inhibitors of this important P450 enzyme by measuring metabolic activity of the endogenous CYP3A4 enzyme. An example of PXR activation without inhibition of CYP3A4 metabolic activity is shown for rifampicin. When a PXR activator is also a time dependent inhibitor, results are obtained that are similar to those shown in panel B above.
						</Typography>
					)}
				/>
			</Grid>
		</Grid >
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard
					content={(
						<Typography variant="body1" color="text.primary">
							The new FDA draft guidance suggests that inducers of transporters be identified during drug development. The guidance states “activation of nuclear receptor assays may be used as an initial evaluation of the induction potential of an investigational drug on transporters”. To that end, Puracyp has developed nuclear receptor activation technology for monitoring P-glycoprotein induction. Assays available include CAR and/or PXR activation to identify compounds that induce P-glycoprotein.
						</Typography>
					)}
					title={(
						<strong>
							Induction of Transporters
						</strong>
					)}
				/>
			</Grid>
		</Grid >
	</Box>
);
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api';

type Props = {
    height: string;
    latitude: number;
    longitude: number;
    isMarkerShown?: boolean;
};

const API_KEY = 'AIzaSyC39Snc_aozo1z010F9qKR0ojlT23T-6z8';
const DEFAULT_ZOOM = 12;

export const GoogleMapComponent = ((props: Props) =>
{

    const containerStyle = {
        borderRadius: '4px',
        height: props.height ? props.height : '100%',
        width: '100%',
    };

    const onLoad = (marker: any) =>
    {
        console.log('marker: ', marker);
    };

    return (
        <LoadScriptNext
            googleMapsApiKey={API_KEY}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                    lat: props.latitude,
                    lng: props.longitude
                }}
                zoom={DEFAULT_ZOOM}
            >
                <MarkerF
                    clickable
                    label="PURACYP"
                    onLoad={onLoad}
                    position={{
                        lat: props.latitude,
                        lng: props.longitude
                    }}
                    title="PURACYP"
                />
            </GoogleMap>
        </LoadScriptNext>
    );
});
import { Box, Container } from '@mui/material';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { InfoBar } from '../../components/InfoBar/InfoBar';
import { NavMenu } from '../../components/Layout/NavMenu';
import { routes } from '../../routes';
import { ThemeProvider } from '@emotion/react';
import { createTheme, responsiveFontSizes } from '@mui/material';
// import backgroundImage from '';
import '../../App.css';

let theme = createTheme();
theme = responsiveFontSizes(theme);

export const Layout = (): ReactElement =>
{
	const content = useRef<HTMLDivElement | undefined>();
	const [ width, setWidth ] = useState<number>(window.innerWidth);

	useEffect(() =>
	{
		const updateWidth = () =>
		{
			setWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateWidth);
		return () => window.removeEventListener('resize', updateWidth);
	}, [ width ]);

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{
				height: '100%',
			}}>
				<NavMenu
					routes={routes}
					width={width}
				/>
				<Box
					ref={content}
					style={{
						margin: '0 auto',
						marginBottom: width > 1420 ? '48px' : '88px',
						marginTop: 0,
						maxWidth: '1440px',
						overflow: 'hidden',
						paddingBottom: '36px',
						paddingTop: '16px',
						width: '100vw'
					}}
				>
					<Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
						<Outlet />
					</Container>
				</Box>
				<InfoBar
					style={{
						bottom: 0,
						height: width > 1420 ? 30 : undefined,
						left: 0,
						paddingRight: '0 !important',
						position: 'fixed',
						right: 0,
					}}
				/>
			</Box>
		</ThemeProvider>
	);
};
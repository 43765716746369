import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './components/Layout/Layout';
import { RouteType } from './components/Layout/NavMenu';

export const App = ({ routes }: { routes: RouteType[]; }): ReactElement =>
(
	<Routes>
		<Route path="/" element={<Layout />} >
			{routes.map((route) =>
				<Route path={route.path} element={route.main} key={route.name} />
			)}
		</Route>
	</Routes>
);

import { Box, Grid, Tooltip } from '@mui/material';
import { Container } from '@mui/system';

export const InfoBar = ({ style }: { style: {}; }) =>
{
	return (
		<Box
			sx={{
				...style,
				alignItems: 'center',
				backgroundColor: 'black',
				color: 'white',
				display: 'flex',
				flexDirection: 'row',
				fontSize: '12px',
			}}
		>
			<Container maxWidth="xl" >
				<Grid container spacing={3} sx={{
					alignItems: 'center'
				}}>
					<Grid item xs={4}
						sx={{ textAlign: 'start' }}
					>
						<Tooltip arrow title="Click to call us!" children={<a style={{
							color: 'white'
						}} href="tel:+18667872297">
							1.866.PURACYP (787.2297)
						</a>} sx={{ backgroundColor: 'black !important' }} />
					</Grid>
					<Grid item xs={4}
						sx={{ textAlign: 'center' }}
					>
						<Tooltip arrow title="Click to email us!" children={<a style={{
							color: 'white'
						}} href="mailto:sales@puracyp.com?subject=Requesting info from your website">
							sales@puracyp.com
						</a>} sx={{ backgroundColor: 'black !important' }} />
					</Grid>
					<Grid item xs={4}
						sx={{ textAlign: 'end' }}
					>
						5900 Sea Lion Place, Suite 130 Carlsbad, CA 92010
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

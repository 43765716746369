import { Card, CardContent, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactElement } from 'react';
import { GoogleMapComponent } from '../Map';
import { Phone } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { InfoCard } from '../Card';

const hQ = {
    height: '400px',
    latitude: 33.134282247511614,
    longitude: -117.24705321109576,
    isMarkerShown: true
};

export const Contact = (): ReactElement =>
{
    return (
        <>
            <Container maxWidth="xl">
                <Card >
                    <CardContent >
                        <Grid container spacing={3}>
                            <Grid md={5} xs={12}>
                                <Typography variant="h6" color="text.primary" sx={{
                                    alignItems: 'center'
                                }}>
                                    <BusinessIcon sx={{ mr: 1 }} />
                                    <strong>Office hours:</strong> 9AM to 5PM PST, Monday - Friday.
                                </Typography>
                                <Typography variant="h6" color="text.primary">

                                    <Phone sx={{ mr: 1 }} /> <strong>Phone:</strong> <a style={{
                                        color: 'black'
                                    }} href="tel:+17609299508">+1 (760) 929-9508</a>

                                </Typography>
                                <Typography variant="h6" color="text.primary">

                                    <Phone sx={{ mr: 1 }} /> <strong>Toll Free:</strong> <a style={{
                                        color: 'black'
                                    }} href="tel:+18667872297">
                                        +1 (866) 787-2297
                                    </a>

                                </Typography>
                                <Typography variant="h6" color="text.primary">

                                    <FaxIcon sx={{ mr: 1 }} /> <strong>Fax:</strong> +1(760) 929-9834

                                </Typography>
                                <Typography variant="h6" color="text.primary">

                                    <EmailIcon sx={{ mr: 1 }} /> <strong>Email:</strong> <a style={{
                                        color: 'black'
                                    }} href="mailto:sales@puracyp.com?subject=Requesting info from your website">
                                        sales@puracyp.com
                                    </a>

                                </Typography>
                                <Typography variant="h6" color="text.primary">

                                    <ContactMailIcon sx={{ mr: 1 }} /> <strong>Mailing address:</strong> 5900 Sea Lion Place, Suite #130 Carlsbad, CA, 92010

                                </Typography>
                            </Grid>
                            <Grid md={7} xs={12}>
                                <GoogleMapComponent {...hQ} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
            <InfoCard content={
                <div style={{
                    textAlign: 'center'
                }}>
                    <Typography variant="h4" component="h1">USA/Canada</Typography>
                    <Typography variant="body1">Puracyp, Inc.</Typography>
                    <Typography variant="body1">Phone: 760.929.9508 ext.102</Typography>
                    <Typography variant="body1">FAX: 760.929.9834</Typography>
                    <Typography variant="body1">
                        <a href="mailto:sales@puracyp.com">Sales@puracyp.com</a>
                    </Typography>
                    <Typography variant="h6" style={{
                        marginBottom: '24px',
                        textDecoration: 'underline'
                    }}>
                        <strong>
                            You may also purchase Puracyp’s nuclear receptor activation services or products from one of our authorized distributors listed below:
                        </strong>
                    </Typography>

                    <Typography variant="h4" component="h1">EUROPE</Typography>

                    <Typography variant="h6" component="h3">Tebu-bio</Typography>
                    <Typography variant="body1">
                        <a href="https://www.tebu-bio.com" style={{ color: 'black' }}>www.tebu-bio.com</a>
                    </Typography>
                    <Typography variant="body1">+33 1 30 46 39 00</Typography>
                    <Typography variant="body1">39 rue de Houdan</Typography>
                    <Typography variant="body1">
                        78612 Le-Perray-en-Yvelines,
                        <strong> France</strong>
                    </Typography>
                </div>
            }
                title="Ordering and Distributors"
            />
            {/* <ContactSection /> */}
        </>
    );
};
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement } from 'react';
import MainImage from '../../../assets/bio11.jpeg';
import { InfoCard } from '../Card';
import DosingMediaChart from 'components/DosingMediaChart/DosingMediaChart';
import ProductChart from 'components/ProductChart/ProductChart';


export const Products = (): ReactElement =>
(
	<Box>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InfoCard content={
					<>
						<Box sx={{ margin: '16px 0' }}>
							<strong>
								Nuclear Receptor Activation Kits
							</strong>
						</Box>
						<Box sx={{ margin: '16px 0' }}>
							<Typography>
								Puracyp’s nuclear receptor activation kits are all-inclusive cell-based assay systems. The kits incorporate mycoplasm-free, stably-transfected cells derived from hepatoma cell lines that have been treated with a cell-arrest agent to prevent further proliferation. Exposure to the cell-arrest agent ensures that the cells in the kits can only be used for a single 96 well assay. Each kit includes the stably integrated receptor and reporter cells.
							</Typography>
						</Box>
						{/* <Divider /> */}
						<Box sx={{ margin: '16px 0' }}>
							<ProductChart />
						</Box>
					</>
				} media={MainImage}
					mediaHeight={394}
					mediaTitle="Cells"
					mediaStyle={{
						margin: 'auto'
					}}
					title="Products" />
			</Grid>
		</Grid>
		<InfoCard content={
			<>
				<Box sx={{ margin: '16px 0' }}>
					<Typography variant="body1" color="text.primary">
						Our culturing and dosing media are formulated to produce optimal activation. Each cell line requires its own culturing and dosing media that is specially formulated. Kits include these media preparations but, extra media is available. Our cryopreserved cells also require Puracyp's specialized media preparations for optimal results.
					</Typography>
				</Box>
				<Box sx={{ margin: '16px 0' }}>
					<DosingMediaChart />
				</Box>
			</>
		}
			title="Culture and Dosing Media"
		/>
	</Box >
);